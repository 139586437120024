function clearFields()
{
    $('#contact_name, #contact_email, #contact_message').val('');
    Materialize.updateTextFields();
}

function showContactData(element, token)
{
    $(element)
        .removeClass("shadow-button contact-decode")
        .html('<p class="loader"><i class="fa fa-spinner fa-spin"></i>' + $('.address').data('msg') + '...</p>');

    $.get("/contact/data/" + $(element).data('type') + "/" + token, {}, function (response) {
        $(element).attr("href", "tel:" + response).html(response);
    });
}

function sendEmail(token)
{
    let contactFormInputs = $('form[name="contact"] :input'),
        contactAlertMessage = $('#contact-alert-message'),
        postData = {
            'contact[name]' : $('#contact_name').val(),
            'contact[email]' : $('#contact_email').val(),
            'contact[message]' : $('#contact_message').val(),
            'contact[_token]' : $('#contact__token').val()
    };

    contactAlertMessage.html(
        '<p><i class="fa fa-spinner fa-spin"></i> ' + contactAlertMessage.data('message') + '...</p>'
    );
    contactFormInputs.prop("disabled", true);

    $.post('/contact/email/send/' + token, postData, function (response) {
        contactAlertMessage.html(
            '<p><i class="fa fa-check-circle-o"></i> ' + response.message + '</p>'
        );
        clearFields();
        contactFormInputs.prop("disabled", false);
    }, 'json')
    .fail(function (response) {
        contactAlertMessage.html(
            '<p><i class="fa fa-times-circle"></i> ' + response.responseJSON.message + '</p>'
        );
        if (response.status === 500) {
            clearFields();
        }

        contactFormInputs.prop("disabled", false);

    }, 'json')
}

$(document).ready(function () {

    $('#contact_form').submit(function (e) {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute($(".address").data('key'), {action: "contact"})
                .then(function (token) {
                    sendEmail(token);
                });
        });
    });

    $(".address a").on("click", function (e) {
        e.preventDefault();
        let element = this;
        grecaptcha.ready(function () {
            grecaptcha.execute($(".address").data('key'), {action: "contact"})
                .then(function (token) {
                    showContactData(element, token);
                });
        });
    });
});